
<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <create-edit-grupo-locacao-dialog :item="item" v-model="editDialogOpened"
                              @onSave="() => $refs.dataTable.refresh()"/>

    <AdicionarClientesDialog :item="item" v-model="addClienteDialogOpened"
                              @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <grupo-locacao-data-table ref="dataTable"
                         @click:addCliente="adicionarCliente"
                         @click:edit="editar"
                         @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import GrupoLocacaoDataTable from "../../components/shared/datatables/GrupoLocacaoDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/grupoLocacao";
import CreateEditGrupoLocacaoDialog from "../../components/shared/dialogs/CreateEditGrupoLocacaoDialog";
import AdicionarClientesDialog from "./components/AdicionarClientesDialog"
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {AdicionarClientesDialog, CreateEditGrupoLocacaoDialog, BaseView, GrupoLocacaoDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      addClienteDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Descricao',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Tipo Curso',
          column: 'tipoCurso',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_TIPO_CURSO
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),    
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    adicionarCliente(grupoLocacao) {
      this.item = JSON.parse(JSON.stringify(grupoLocacao));
      this.addClienteDialogOpened = true;
    },
    editar(grupoLocacao) {
      this.item = JSON.parse(JSON.stringify(grupoLocacao));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`GrupoLocacao removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover o GrupoLocacao.`, {
          timeout: 6000
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>