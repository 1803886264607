<template>
<v-row>
  <v-col cols="12">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="* Nome"
                :rules="[
                      this.$validators.string.required,
                          v => this.$validators.string.greaterThanOrEquals(v, 1),
                          v => this.$validators.string.lessThanOrEquals(v, 150)

                  ]"
                v-model.trim="value.nome"/>
    <v-text-field v-else label="* Nome"
                  dense
                  :rules="[
                      this.$validators.string.required,
                          v => this.$validators.string.greaterThanOrEquals(v, 1),
                          v => this.$validators.string.lessThanOrEquals(v, 150)

                  ]"
                  v-model.trim="value.nome"/>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: "GrupoLocacaoForm",
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>