<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="1200px"
          persistent>
    <v-card class="d-flex flex-column" :loading="loading">
      <v-card-title>
        {{getTitle}}
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" class="">
              <v-card class="px-1">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12">
                        <v-text-field label="Pesquise pelo nome para incluir no grupo"
                                    dense
                                    v-model.trim="nomeBusca"/>
                    </v-col>
                    <v-col cols="12" style="height:60px !important;">
                        <div class="overflow-x-auto" style="max-height: 50px">
                          <v-chip color="#5E69D1" class="ma-1" small text-color="white"
                            v-for="cliente in clienteOut" :key="cliente.id">
                            <v-icon left>mdi-account</v-icon>
                            <a @click="add(cliente)">{{cliente.nome}}</a>
                          </v-chip>
                        </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="">
              <v-card class="px-1">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12">
                        <v-icon large left >mdi-account</v-icon>
                        <span class="title font-weight-bold">Participantes do Grupo</span>
                    </v-col>
                    <v-col cols="12" style="height:190px !important;">
                        <div class="overflow-x-auto" style="max-height: 180px">
                          <v-chip color="#F39C11" class="ma-1" small text-color="white"
                            v-for="cliente in clientesIn" :key="cliente.id">
                            <v-icon left>mdi-account</v-icon>
                            <a @click="del(cliente)">{{cliente.nomeCliente}}</a>
                          </v-chip>
                        </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchBox from "../../../components/shared/common/SearchBox";
import {findAll as findAllClientes} from "../../../api/clientes";
import {findAll as findAllGrupoClientes, create, remove} from "../../../api/grupoLocacaoCliente";
import {ApiError} from "../../../api/core";

export default {
  name: "AdicionarClientesDialog",
  components: {SearchBox},
  props: ['value','item'],
  data () {
    return {
      nomeBusca: "",
      loading: false,
      error: null,
      clienteOut: [],        
      clientesIn: [],
    }
  },
  computed: {
    getTitle() {
      return 'Grupo Locação: '+ this.item?.nome;
    },
    clientesSelecionados() {
      return (this.clientesIn || []).map(d => d.idCliente)
    },
  },
  watch: {
    nomeBusca: {
      deep: true,
      handler() {
        this.loaderClientes();
      }
    },
    'item.id': {
      deep: true,
      handler() {
        this.nomeBusca = ""
        this.loaderClientesIn();
      }
    }
  },  
  methods: {
    async loaderClientesIn() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllGrupoClientes(1, {
            idGrupoLocacao: this.item.id,
        });
        this.clientesIn = page.elements;
        this.loaderClientes(); 
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },     
    async loaderClientes() {
      try {
        this.clienteOut = []
        if (this.nomeBusca != "") {
          this.error = null;
          this.loading = true;
          const page = await findAllClientes(1, {
              nome: this.nomeBusca,
              idsNotIn: this.clientesSelecionados
          });
          this.clienteOut = page.elements;
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },    
    async add(cliente) {
      try {
        if(this.$refs.form.validate()) {
          await create({
                  idGrupoLocacao: this.item.id,
                  idCliente: cliente.id,
              })
          this.loaderClientesIn();
          this.$toast.success(`Cliente adicionado ao Grupo Locação com sucesso.`, {
            timeout: 3000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível adicionar o Cliente ao Grupo Locação.`, {
            timeout: 6000
          })
        }
      }
    },
    async del(cliente){
      console.log('Vai apagar: '+this.item.id+' - '+cliente.idCliente)
      try {
        await remove(this.item.id, cliente.idCliente);
        this.loaderClientesIn();
        this.$toast.success(`Cliente removido do Grupo com sucesso.`, {
          timeout: 3000
        });
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {        
          this.$toast.error(`Não foi possível remover o Cliente do Grupo.`, {
            timeout: 6000
          })
        }
      }  
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
  .verde {
    color: green;
  }
  .vermelho {
    color: red;
  }
</style>